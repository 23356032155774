import dateFormats from './date-formats.config';
import { deepValue } from '@borg/utils';

export default defineI18nConfig(() => {
  const config = useRuntimeConfig();

  return {
    legacy: false,
    globalInjection: true,
    messageResolver: (obj, path) => {
      if (config.public.dontTranslateFeStrings === 'true') {
        if (path.includes('SLUG')) {
          return deepValue(obj, path) as string;
        }
        return path;
      } else {
        return deepValue(obj, path) as string;
      }
    },
    datetimeFormats: {
      en: dateFormats(),
      hr: dateFormats(),
    },
  };
});
