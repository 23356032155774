export default (): Record<string, Intl.DateTimeFormatOptions> => ({
  'long': {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  'exp': {
    year: 'numeric',
    month: 'long',
  },
  'monthYear': {
    year: 'numeric',
    month: 'long',
  },
  'year': {
    year: 'numeric',
  },
  'dmy-short': {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  'dmy': {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  },
  'dmy-time': {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
});
